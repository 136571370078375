export const projects = [
    {
        id: 0,
        name: "Build Week 1 - Web Fundamentals (HTML & CSS)",
        deployedURL: "https://better-professor-ui.netlify.com",
        image: "",
        description: "This was my first group project. I had three weeks of coding under my belt. I had to use HTML and CSS to build and design a landing page for our 'Better Professor App'. My teammate and I designed drafts and then combined the best elements. We worked for three days together pushing and pulling code form GitHub. With this project, I learned how to add icons into an HTML document and experienced my first GitFlow.",
        tags: "HTML, CSS, FONT-AWESOME, LANDING PAGE, GITHUB"
    },
    {
        id: 1,
        name: "Build Week 2 - JavaScript & React",
        deployedURL: "https://cookbooker.netlify.com",
        image: "",
        description: "Spicy jalapeno bacon ipsum dolor amet ut bacon laboris irure leberkas. Ground round bresaola dolore veniam beef ribs proident officia dolor leberkas dolore eiusmod aute frankfurter shankle pork belly. Ipsum ham hock salami sirloin, spare ribs shoulder ground round commodo non pork loin fugiat sunt. Pork belly turducken buffalo ut rump dolor bacon. Exercitation et t-bone chuck ex. Pig chicken brisket ribeye, hamburger ullamco occaecat ut duis incididunt capicola ham burgdoggen. ",
        tags: "JAVASCRIPT, REACT, REACT-ROUTER, STATE, HOOKS"
    },
    {
        id: 2,
        name: "Build Week 1 - App landing page",
        deployedURL: "https://team-builder.now.sh/",
        image: "",
        description: "Spicy jalapeno bacon ipsum dolor amet ut bacon laboris irure leberkas. Ground round bresaola dolore veniam beef ribs proident officia dolor leberkas dolore eiusmod aute frankfurter shankle pork belly. Ipsum ham hock salami sirloin, spare ribs shoulder ground round commodo non pork loin fugiat sunt. Pork belly turducken buffalo ut rump dolor bacon. Exercitation et t-bone chuck ex. Pig chicken brisket ribeye, hamburger ullamco occaecat ut duis incididunt capicola ham burgdoggen. ",
        tags: "HTML, CSS, FONT-AWESOME, LANDING PAGE, GITHUB"
    },
    {
        id: 3,
        name: "Build Week 1 - App landing page",
        deployedURL: "https://reducer-todo.now.sh/",
        image: "",
        description: "Spicy jalapeno bacon ipsum dolor amet ut bacon laboris irure leberkas. Ground round bresaola dolore veniam beef ribs proident officia dolor leberkas dolore eiusmod aute frankfurter shankle pork belly. Ipsum ham hock salami sirloin, spare ribs shoulder ground round commodo non pork loin fugiat sunt. Pork belly turducken buffalo ut rump dolor bacon. Exercitation et t-bone chuck ex. Pig chicken brisket ribeye, hamburger ullamco occaecat ut duis incididunt capicola ham burgdoggen. ",
        tags: "HTML, CSS, FONT-AWESOME, LANDING PAGE, GITHUB"
    },
    {
        id: 4,
        name: "Build Week 1 - App landing page",
        deployedURL: "https://smurfs.seanaleid.now.sh/",
        image: "",
        description: "Spicy jalapeno bacon ipsum dolor amet ut bacon laboris irure leberkas. Ground round bresaola dolore veniam beef ribs proident officia dolor leberkas dolore eiusmod aute frankfurter shankle pork belly. Ipsum ham hock salami sirloin, spare ribs shoulder ground round commodo non pork loin fugiat sunt. Pork belly turducken buffalo ut rump dolor bacon. Exercitation et t-bone chuck ex. Pig chicken brisket ribeye, hamburger ullamco occaecat ut duis incididunt capicola ham burgdoggen. ",
        tags: "HTML, CSS, FONT-AWESOME, LANDING PAGE, GITHUB"
    },
]

export default projects;